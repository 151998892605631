export const cameraPositions = {
  CLOSE: 0,
  FRONT: 1,
  GENERAL: 2,
}

export const displayStatus = {
  OFF: 0,
  ON: 1,
}

export const displayContentTypes = {
  BLANK: 0,
  IMAGE: 1,
  VIDEO: 2,
  DOOM: 666,
}
